import React from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  return (
    <nav style={{ background: '#282c34', padding: '1rem' }}>
      <ul style={{ display: 'flex', justifyContent: 'space-around', listStyle: 'none', color: '#fff' }}>
        <li><Link to="/" style={{ color: '#61dafb', textDecoration: 'none' }}>Главная</Link></li>
        <li><Link to="/about" style={{ color: '#61dafb', textDecoration: 'none' }}>Обо мне</Link></li>
        <li><Link to="/portfolio" style={{ color: '#61dafb', textDecoration: 'none' }}>Портфолио</Link></li>
        <li><Link to="/testimonials" style={{ color: '#61dafb', textDecoration: 'none' }}>Отзывы</Link></li>
        <li><Link to="/contact" style={{ color: '#61dafb', textDecoration: 'none' }}>Контакты</Link></li>
      </ul>
    </nav>
  );
};

export default Navbar;
