import React from 'react';

const Home = () => {
  return (
    <div style={{ textAlign: 'center', padding: '2rem' }}>
      <h1 style={{ fontSize: '3rem', color: '#61dafb' }}>Добро пожаловать!</h1>
      <p style={{ fontSize: '1.5rem', color: '#282c34' }}>
        Это приложение, посвященное Arthur Klein.
      </p>
    </div>
  );
};

export default Home;
